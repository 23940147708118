<template>
  <div class="profile-information-wrapper">
    <h3>
      {{ $t('profile.nav.profile') }}
    </h3>
    <div class="profile-information-wrapper-item">
      <div class="left-content">
      {{ $t('profile.nav.picture') }}
      </div>
      <div class="right-content">
        <img :src="avatar" class="avatar" @click="goPicture"/>
        <img src="@/assets/images/profile/rightArrow.svg"/>
      </div>
    </div>
    <div class="profile-information-wrapper-item">
      <div class="left-content">
        {{ $t('profile.verifications.verifications') }}
      </div>
      <div class="right-content main-text-3 ub ub-cen" @click="goAuthentication">
        <span> {{ $t('profile.verifications.Unverified') }}</span>
        <img src="@/assets/images/profile/rightArrow.svg"/>
      </div>
    </div>
    <div class="profile-information-wrapper-item">
      <div class="left-content">
        UID
      </div>
      <div class="right-content ub ub-cen">
        <span class="main-text-3">{{ uid }}</span>
        <CopyWrap :text="uid">
          <img src="@/assets/images/profile/copy.svg" />
        </CopyWrap>
      </div>
    </div>
    <div class="profile-information-wrapper-item">
      <div class="left-content">
        {{ $t('profile.nav.nickname') }}
      </div>
      <div class="right-content ub ub-cen" @click="goNickName">
        <span class="main-text-3">{{ nickname }}</span>
        <img src="@/assets/images/profile/rightArrow.svg"/>
      </div>
    </div>
    <van-button type="primary" block :color="btnColor" class="btn-logout" @click="show = true">{{ $t('profile.logOut') }}</van-button>
    <van-dialog v-model="show" :confirm-button-text="$t('profile.logOut')" :cancel-button-text="$t('nav.cancel')" show-cancel-button @confirm="onLogOut">
      <template #title>
        <div class="text-l">{{ $t('profile.logOut') }}</div>
      </template>
      <div class="dialog-p48">
        {{ $t('profile.logOutText') }}
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import CopyWrap from '@/components/copy/index.vue'

export default {
  components: { CopyWrap },
  data () {
    return {
      show: false,
      btnColor: 'var(--error-color)'
    }
  },
  computed: {
    uid () {
      return this.$store.state.userInfo?.user_id
    },
    nickname () {
      return this.$store.state.userInfo?.nickname
    },
    avatar () {
      return this.$store.state.userInfo?.avatar_address || require('@/assets/images/profile/defaultAvatar.svg')
    }
  },
  methods: {
    goAuthentication () {
      this.$router.push('/profile/authentication')
    },
    goPicture () {
      this.$router.push('/profile/picture')
    },
    goNickName () {
      this.$router.push('/profile/nickname')
    },
    onLogOut () {
      this.LogOut()
      this.$toast(this.$t('profile.logOut'))
      this.$nextTick(() => {
        this.$router.push('/profile')
      })
    },
    ...mapActions(['LogOut'])
  }
}
</script>
<style lang="less">
.profile-information-wrapper {
  padding: 0 30px;
  background-color: var(--background-color-4);
  .text-l {
    padding: 0 48px;
    color: var(--main-text-1);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 150% */
  }
  .dialog-p48 {
    padding: 0 48px;
    margin: 16px 0;
    color: var(--main-text-1);
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  h3 {
    padding: 20px 0 64px 0;
    margin: 0;
    color: var(--main-text-1);
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; /* 150% */
  }
  .btn-logout {
    position: fixed;
    bottom: 20px;
    left: 50%;
    width: 90%;
    transform: translateX(-50%);
    border-radius: 8px;
  }
  &-item {
    display: flex;
    width: 100%;
    height: 96px;
    justify-content: space-between;
    align-items: center;
    img {
      width: 48px;
      height: 48px;
    }
    .avatar {
      border-radius: 60px;
    }
    .left-content {
      color: var(--main-text-1);
      font-family: Roboto;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 48px; /* 150% */
    }
    .right-content {
      line-height: 0;
    }
  }
}
</style>
